<template>
  <div class="page">
    <el-container class="page-content">
      <el-main width="1240px" class="page-content-l" style="padding:0px;">
        <div class="tab">
          <div class="backImg" @mouseenter="test" @mouseleave="test">
           <img :src="flag ? one : two" alt="" @click="goback" />
          </div>
          <div class="dspan" :class="current == 0 ? 'add' : ''" @click="qiehuan(0)"
            >推荐记录</div
          >
          <div class="dspan" :class="current != 0 ? 'add' : ''" @click="qiehuan(1)"
            >主播数据</div
          >
        </div>
        <anchor
          v-show="current == 0 && anchorList.length != 0 || current==0&&anchorList1.length!=0"
          :anchorList="anchorList" :anchorList1="anchorList1" :tab="tab"
        />
        <accord
          v-show="current == 1"
          :accordList="accordList" :accordList1="accordList1" :tab="tab" @tabClick="tabTap"
        />
        <empty
          v-show="
            (current == 0 && anchorList.length == 0 && tab == 1) ||
              (current == 0 && anchorList1.length == 0 && tab == 2)
          "
        />
      </el-main>
    </el-container>
  </div>
</template>
<script>
import anchor from "./item/anchor";
import accord from "./item/accord";
export default {
  data() {
    return {
      tabList: ["推荐记录", "主播数据"],
      current: 0,
      anchorList: [], //推荐记录星探
      accordList: [], //主播数据星探
      accordList1:[],//王牌运营
      anchorList1:[],//王牌运营
      tabValue:1,
      flag:true,
      one: require("@/static/starImg/back.png"),
      two: require("@/static/starImg/back1.png"),
      tab:'1',//星探入口
    };
  },
  components: {
    anchor,
    accord
  },
  created() {
    this.tab = this.$route.query.tab;
    console.log(this.tab)
    this.getTjData1();
    this.getAnData1();
    this.getTjData();
    this.getAnData();
  },
  methods: {
    test(){
      this.flag = !this.flag;
    },
     goback(){
      this.$router.go(-1)
    },
    tabTap(val){
      this.tabValue = val
      this.getAnData1()
      this.getAnData()
    },
    qiehuan(num) {
      this.current = num;
    },
    async getTjData() {
      const { data, status } = await this.$api.anchorRecomandApi({
        visit_type: "user"
      });
      if (status == 200) {
        console.log(data);
        this.anchorList = data;
      }
    },
    async getTjData1() {
      const { data, status } = await this.$api.anchorRecomandApi({
        visit_type: "broker"
      });
      if (status == 200) {
        console.log(data);
        this.anchorList1 = data;
      }
    },
    async getAnData() {
      const { data, status } = await this.$api.anchorInfoApi({
        visit_type: "broker",
        plate_form: this.tabValue
      });
      if (status == 200) {
        console.log(data);
        this.accordList = data;
      }
    },
    async getAnData1() {
      const { data, status } = await this.$api.anchorInfoApi({
        visit_type: "user",
        plate_form: this.tabValue
      });
      if (status == 200) {
        console.log(data);
        this.accordList1 = data;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-main {
  overflow: hidden;
}
.page {
  background: #f7f7f7;
  min-height: 100vh;
  border: solid 1px #f7f7f7;
}
.page-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 50px;
}
.tab {
  display: flex;
  height: 65px;
  line-height: 74px;
  position: relative;
  .backImg{
    width: 24px;
    height: 24px;
    margin-right: 10px;
    img{
      width: 100%;
      height: 100%;
      margin-top: 25px;
    }
  }
  .dspan {
    height: 25px;
    font-size: 16px;
    color: #999999;
    margin-right: 30px;
    cursor: pointer;
  }
}
.add {
  color: #333333 !important;
}
</style>