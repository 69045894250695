<template>
  <div class="page">
    <div class="right">
      <el-dropdown @command="sumTypeCkeck">
        <div
          style=" display: flex;align-items: center;height: 100%;font-size:14px;"
        >
          {{ tabType }}<i class="el-icon-caret-bottom el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in ttabList"
            :key="index"
            v-text="item.title"
            :command="index"
          ></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table :data="accordList" :header-cell-style="{ background: '#fff' }">
      <el-table-column label="主播姓名/直播ID" min-width="200">
        <template slot-scope="scope">
          <div style="display:flex;align-items:center;">
            <el-avatar :size="64" :src="scope.row.avatar"></el-avatar>
            <divz
              style="margin-left:10px"
              class="flex flex-direction justify-around "
            >
              <p>
                <span>{{ scope.row.tick_name }}</span>
              </p>
              <p>ID：{{ scope.row.tick_account }}</p>
            </divz>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="推荐人手机号" min-width="180">
        <template slot-scope="scope">
          <div>
            <p>电话：{{ scope.row.scout_phone }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="分成比" min-width="140">
        <template slot-scope="scope">
          <div>
            <p>{{ scope.row.separate_ratio }}</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="本月流水" min-width="140">
        <template slot-scope="scope">
          <div>
            <p>{{ scope.row.month_flow }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="签约时间" min-width="180">
        <template slot-scope="scope">
          <div>
            <p>{{ scope.row.contract_time }}</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="所属王牌运营" min-width="140">
        <template slot-scope="scope">
          <div>
            <p>{{ scope.row.broker }}</p>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tab: {
      type: String,
      default: "1"
    },
    accordList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tabType: "探探",
      ttabList: [], //下拉菜单平台选项
      tabValue: 1,
      tableData: [
        {
          avatar:
            "https://auto.tancdn.com/v1/images/eyJpZCI6Ik1aRVYzQkY1T0NKTEVKRFhBRE9OTUtPUERVVU5YNjA3IiwidyI6MTA4MCwiaCI6MTM1MCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE1NDQ2MDUxNjQ0NTEzNTYwMjcxfQ",

          flow: "0",
          contract_time: "2020-11-06",
          month_flow: 0,
          nick_name: "你的小初一呀",
          phone: "18676270970",
          separate_ratio: 0,
          settlement_type: 1,
          tick_account: "2079411587"
        },
        {
          avatar:
            "https://auto.tancdn.com/v1/images/eyJpZCI6Ik1aRVYzQkY1T0NKTEVKRFhBRE9OTUtPUERVVU5YNjA3IiwidyI6MTA4MCwiaCI6MTM1MCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE1NDQ2MDUxNjQ0NTEzNTYwMjcxfQ",

          flow: "0",
          contract_time: "2020-11-06",
          month_flow: 0,
          nick_name: "你的小初一呀",
          phone: "18676270970",
          separate_ratio: 0,
          settlement_type: 1,
          tick_account: "2079411587"
        },
        {
          avatar:
            "https://auto.tancdn.com/v1/images/eyJpZCI6Ik1aRVYzQkY1T0NKTEVKRFhBRE9OTUtPUERVVU5YNjA3IiwidyI6MTA4MCwiaCI6MTM1MCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE1NDQ2MDUxNjQ0NTEzNTYwMjcxfQ",

          flow: "0",
          contract_time: "2020-11-06",
          month_flow: 0,
          nick_name: "你的小初一呀",
          phone: "18676270970",
          separate_ratio: 0,
          settlement_type: 1,
          tick_account: "2079411587"
        }
      ]
    };
  },
  created() {
    this.getTabData();
  },
  methods: {
    async getTabData(params = {}) {
      const { data, status } = await this.$api.anchorTabApi(params);
      if (status == 200) {
        console.log(data);
        this.ttabList = data;
      }
    },
    sumTypeCkeck(index) {
      this.tabType = this.ttabList[index].title;
      this.tabValue = this.ttabList[index].value;
      this.$emit("tabClick", this.tabValue);
      console.log(this.tabValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.right {
  position: absolute;
  right: 0;
  top: -5%;
  z-index: 100;
}
.page {
  position: relative;
}
</style>